import styled, {createGlobalStyle} from 'styled-components'
import {GlobalBackgroundColors, GlobalColors} from '../../../styles/GlobalStyles';
const heightClose = '40px';
const paddingClose = '25px';

export const GlobalStyleModal = createGlobalStyle`
  .rec-carousel-wrapper-carousel-in-modal{
    top: 0 !important;
  }
  .button-arrow-carousel-in-modal{
    font-size: 40px;
    color: ${GlobalColors.colorMenuPrimary};
    cursor: pointer;
  }
  .cont-slider-arrow-carousel-in-modal-l{
    position: absolute;
    top: 40%;
    left: 5%;
    z-index: 9;
  }
  .cont-slider-arrow-carousel-in-modal-r{
    position: absolute;
    top: 40%;
    right: 5%;
    z-index: 9;
  }
  .ModalRGB .rec-item-wrapper{
    justify-content: center !important;
  }
`

export const ModalRGB = styled.div`
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  overflow: hidden;
  background-color: ${GlobalBackgroundColors.bgPrimary}99;
  z-index: 999;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
export const ContTitleModalRGB = styled.div`
  position: absolute;
  width: 100%;
  height: calc(${heightClose} + ${paddingClose});
  top: 10px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 9;
`
export const TitleModalRGB = styled.div`
  width: 95%;
  height: ${heightClose};
`
export const CloseModalRGB = styled.div`
  width: 5%;
  height: ${heightClose};
  cursor: pointer;
`
export const ImgModalRGB = styled.img`
  width: 100%;
  height: ${heightClose};
`
export const ContBodyModalRGB = styled.div`
  width: 100%;
  height: auto;
`
